<template>
  <div id="main" class="main">  
    <NavBar/>
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
      <strong v-if="isSubmitted" class="m-auto mt-8">กำลังดำเนินการ กรุณารอสักครู่</strong>
    </div>

    <!-- Error -->
    <div v-else-if="error" class="content">
      <div class="header text-center mt-16">
        <p v-if="error_not_have_course">ไม่มีรายวิชาที่ลงทะเบียนไว้ในปัจจุบัน กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลรายวิชา</p>
        <p v-else-if="error_add_expired">สิ้นสุดระยะเวลาการกรอกแบบฟอร์มนำเสนอหัวข้อโครงงาน</p>
        <p v-else-if="error_edit_expired">สิ้นสุดระยะเวลาการแก้ไขข้อมูลโครงงาน</p>
      </div>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <form v-on:submit.prevent="submit">
        <!-- Header -->
        <div class="row">
          <p v-if="project.id" class="project-header mb-4">
            {{ !translate ? 'แก้ไขรายละเอียดโครงงาน' : 'Edit' }} {{ old_topic_th }}<br>
            <span class="whitespace-pre-line text-lg">({{old_topic_en}})</span>
          </p>
          <p v-else class="project-header">{{ !translate ? 'แบบฟอร์มเสนอหัวข้อโครงงาน' : 'Project proposal form' }}</p>
          <div v-if="role === 'STD'" v-on:click="translate = !translate" class="language-button">{{ !translate ? 'EN' : 'TH' }}</div>
        </div>

        <!-- Warning -->
        <div v-if="!translate" class="text-red-500 mb-8">*กรุณาตรวจสอบรายวิชาอย่างถี่ถ้วน (หลักสูตร และปีการศึกษา) หากพบรายวิชาที่ไม่เกี่ยวข้องกับนักศึกษา กรุณาอย่ากรอกข้อมูลแบบฟอร์มนี้*</div>
        <div v-else class="text-red-500 mb-8">*Please check the course carefully (Curriculum and academic year) if you find any courses that are unrelated to you. Please do not fill out this form.*</div>

        <!-- Project topic (Thai) -->
        <div class="form-div">
          <p class="label col-span-2">{{ !translate ? 'หัวข้อโครงงาน (ภาษาไทย)' : 'Project topic (TH)' }}</p>
          <input v-model="project.topic_th" type="text" class="col-span-3" required :disabled="is_advisor">
        </div>

        <!-- Project topic (English) -->
        <div class="form-div">
          <p class="label col-span-2">{{ !translate ? 'หัวข้อโครงงาน (ภาษาอังกฤษ)' : 'Project topic (EN)' }}</p>
          <input v-model="project.topic_en" type="text" class="col-span-3" required :disabled="is_advisor">
        </div>

        <!-- Project Type (Course) -->
        <div class="form-div">
          <p class="label col-span-2">{{ !translate ? 'รายวิชา' : 'Course' }} <sup>[1]</sup></p>
          <input v-if="project.id != null" :value="project.course_details.type_name" type="text" class="col-span-2" disabled>
          <select v-else v-model="project.course" @change="onChange(this.value)"  class="col-span-2" :disabled="is_advisor">
            <option :value="null">-</option>
            <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.type_name }}</option>
          </select>
        </div>

        <!-- Course: null Error -->
        <div v-if="!translate">
          <p class="project-text-error" v-if="val.course">กรุณากรอกข้อมูลสำหรับ "รายวิชา"</p>
          <small class="ml-4"><sup>[1]</sup> 1.หากนักศึกษาไม่พบข้อมูลรายวิชา กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลรายวิชา</small><br>
          <small class="ml-8">2.ตรวจสอบรายวิชา หลักสูตร และปีการศึกษาให้ถูกต้อง เนื่องจากนักศึกษาจะไม่สามารถแก้ไขข้อมูลในส่วนนี้ได้</small><br>
          <small class="ml-8">3.หากนักศึกษาพบรายวิชาที่ไม่เกี่ยวข้องกับตนเอง กรุณาอย่าสร้างโครงงานใหม่ เนื่องจากมีผลต่อการสอบ</small>
        </div>
        <div v-else>
          <p class="project-text-error" v-if="val.course">Please fill out the information for "Course"</p>
          <small class="ml-4"><sup>[1]</sup> 1.If you couldn't find your course. Please contact the staff.</small><br>
          <small class="ml-8">2.Verify that the course name, curriculum and academia year are correct. Because you will not be able to edit information in this section.</small><br>
          <small class="ml-8">3.If you found a course that isn't related to you. Please do not create a new project because it affects the exam system.</small>
        </div>
        <hr>

        <!-- Student -->
        <p class="label mb-4">{{ !translate ? 'นักศึกษาผู้รับผิดชอบ' : 'Project members' }}</p>
        <!-- Student: Edit project -->
        <div v-if="project.id">
          <div v-for="(member, index) in project.member_details" :key="member.id" class="form-div">
            <p v-if="index == 0" class="label">{{ !translate ? 'ชื่อ-นามสกุล' : 'Student name' }}</p>
            <p v-else></p>
            <p v-if="index == 0" class="col-span-3">{{ member.name }}</p>
            <p v-if="index == 1 && type == 'PJ' && !member.has_project && !member.in_course" class="col-span-3">{{ member.name }}</p>
            <p v-if="index == 1 && type == 'PJ' && member.has_project || index == 1 && type == 'PJ' && member.in_course" class="col-span-3 text-red-500">{{ member.name }}</p>
            <p v-if="index == 0" class="label">{{ !translate ? 'รหัสนักศึกษา' : 'ID' }}</p>
            <p v-else></p>
            <input v-model="member.code" v-if="index == 0 && type !== 'PJ'" type="text" class="readonly" readonly>
            <input v-model="code_1" v-if="index == 0 && type == 'PJ'" type="text" maxlength=8 :disabled="is_advisor">
            <input v-model="code_2" v-if="index == 1 && type == 'PJ'" type="text" maxlength=8 :disabled="is_advisor">
          </div>
        </div>

        <!-- Student: Create project -->
        <div v-else>
          <!-- Members: self -->
          <div class="form-div">
            <p class="label">{{ !translate ? 'ชื่อ-นามสกุล' : 'Student name' }}</p>
            <p class="col-span-3">{{ project.member_details[0].name }}</p>
            <p class="label">{{ !translate ? 'รหัสนักศึกษา' : 'ID' }}</p>
            <input v-model="project.member_details[0].code" type="text" class="readonly" readonly>
          </div>
          <!-- Member: mate -->
          <div v-if="type === 'PJ' && project.course != null && project.member_details.length > 1" class="form-div">
            <div></div>
            <p v-if="!project.member_details[1].has_project && !project.member_details[1].in_course" class="col-span-3">{{ project.member_details[1].name }}</p>
            <p v-if="project.member_details[1].has_project || project.member_details[1].in_course" class="col-span-3 text-red-500">{{ project.member_details[1].name }}</p>
            <div></div>
            <input v-model="code_2" type="text" maxlength=8>
          </div>
        </div>

        <!-- Student: error -->
        <div v-if="!translate">
          <p v-if="val_student_project" class="project-text-error">{{ project.member_details[1].name }} ({{ project.member_details[1].code }}) มีโครงงานที่รับผิดชอบแล้ว</p>
          <p v-if="val_student_course" class="project-text-error">{{ project.member_details[1].name }} ({{ project.member_details[1].code }}) ไม่มีรายชื่อในรายวิชานี้ กรุณาติดต่อเจ้าหน้าที่</p>
          <small v-if="type === 'PJ'" class="ml-4">หากนักศึกษารับผิดชอบโครงงานเพียงคนเดียว นักศึกษาไม่ต้องกรอกรหัสนักศึกษาในอีกหนึ่งช่องว่าง</small>
        </div>
        <div v-else>
          <p v-if="val_student_project" class="project-text-error">{{ project.member_details[1].name }} ({{ project.member_details[1].code }}) already have a project.</p>
          <p v-if="val_student_course" class="project-text-error">{{ project.member_details[1].name }} ({{ project.member_details[1].code }}) is not in this course. Please contact the staff.</p>
          <small v-if="type === 'PJ'" class="ml-4">If you are doing this project alone, You don't have to fill another student id.</small>
        </div>
        <hr>

        <!-- Advisor -->
        <div>
          <!-- Advisor -->
          <div class="form-div">
            <p class="label col-span-2">{{ !translate && type === 'CP' ? 'อาจารย์ที่ปรึกษา (นิเทศ)' : !translate && type !== 'CP' ? 'อาจารย์ที่ปรึกษา' : 'Advisor' }}</p>
            <input v-if="is_advisor && project.id != null" :value="project.advisor_details[0].name" type="text" class="col-span-2" :disabled="is_advisor">
            <select v-else v-model="project.advisors[0]" class="col-span-2" :disabled="is_advisor">
              <option :value="null">-</option>
              <option v-for="teacher in teachers" :key="teacher.id" :value="teacher.id">{{ teacher.name }}</option>
            </select>
          </div>

          <!-- Co-advisor -->
          <div class="form-div">
            <p class="label col-span-2">{{ !translate ? 'อาจารย์ที่ปรึกษาร่วม (ถ้ามี)' : 'Co-advisor (if applicable)' }}</p>
            <input v-if="is_advisor && project.id != null" :value="project.advisor_details[1].name" type="text" class="col-span-2" :disabled="is_advisor">
            <select v-else v-model="project.advisors[1]" class="col-span-2" :disabled="is_advisor">
              <option :value="null">-</option>
              <option v-for="teacher in teachers" :key="teacher.id" :value="teacher.id">{{teacher.name}}</option>
            </select>
          </div>

          <!-- Advisor: error -->
          <p v-if="val.advisor_null && !translate" class="project-text-error">กรุณากรอกข้อมูลสำหรับ "อาจารย์ที่ปรึกษา"</p>
          <p v-else-if="val.advisor_null && translate" class="project-text-error">Please fill out the information for "Advisor".</p>
          <p v-else-if="val.advisor_same && !translate" class="project-text-error">ไม่สามารถเลือกอาจารย์ที่ปรึกษาซ้ำกับอาจารย์ที่ปรึกษาร่วมได้</p>
          <p v-else-if="val.advisor_same && translate" class="project-text-error">You can't select the same advisor as a co-advisor.</p>

          <!-- Company name (Coop) -->
          <div v-if="type === 'CP'" class="form-div">
            <p class="label col-span-2">{{ !translate ? 'ชื่อบริษัท' : 'Name of the establishment' }}*</p>
            <input v-model="project.company.name" type="text" class="col-span-3">
          </div>

          <!-- Company advisor name (Coop) -->
          <div v-if="type === 'CP'" class="form-div">
            <p class="label col-span-2">{{ !translate ? 'ชื่อ-นามสกุล พนักงานที่ปรึกษา' : 'Job supervisor full name' }}*</p>
            <input v-model="project.company.advisor" type="text" class="col-span-3" :placeholder="!translate ? 'ตัวอย่าง: นายสมหมาย ใจกล้า' : 'Example: Mr.Sommai Jaigla'">
          </div>
          
          <!-- Company advisor email (Coop) -->
          <div v-if="(type === 'CP' && is_advisor && role === 'TCH') || (type === 'CP' && role === 'STF')" class="form-div">
            <p class="label col-span-2">{{ !translate ? 'อีเมลพนักงานที่ปรึกษา' : 'Job supervisor E-mail' }}*</p>
            <input v-model="project.company.email" type="text" class="col-span-3">
          </div>
        </div>

        <!-- Suggestion (Coop) -->
        <small v-if="type === 'CP' && !translate" class="ml-4"><sup>*</sup> หากยังไม่ทราบข้อมูลในส่วนที่เกี่ยวข้องกับบริษัทสหกิจศึกษา นักศึกษาสามารถกรอกข้อมูลในการแก้ไขโครงงานได้ภายหลัง</small>
        <small v-if="type === 'CP' && translate" class="ml-4"><sup>*</sup> If you still don't know the information related to the establishment. You can fill out this information later.</small>
        <hr>

        <!-- Category -->
        <div class="form-div">
          <p class="label col-span-2">{{ !translate ? 'หมวดหมู่ของโครงงาน' : 'Project category' }} <sup> [2]</sup></p>
          <input v-if="is_advisor && project.id != null" :value="project.category_detail.name" type="text" class="col-span-2" :disabled="is_advisor">
          <select v-else v-model="project.category" class="col-span-2" :disabled="is_advisor">
            <option :value="null">-</option>
            <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
          </select>
        </div>

        <!-- Category: null error -->
        <div v-if="!translate">
          <p v-if="val.category" class="project-text-error">กรุณากรอกข้อมูลสำหรับ "หมวดหมู่ของโครงงาน"</p>
          <small class="ml-4"><sup>[2]</sup> หากไม่พบหมวดหมู่ที่สอดคล้องกับโครงงานของนักศึกษา ให้เลือกหมวดหมู่ของโครงงานที่ใกล้เคียงกับโครงงานมากที่สุด</small>
        </div>
        <div v-else>
          <p v-if="val.category" class="project-text-error">Please fill out the information for "Project category"</p>
          <small class="ml-4"><sup>[2]</sup> If you not found a category that corresponds to your project. Please selece a category that is closest to your project.</small>
        </div>

        <!-- Keyword -->
        <div class="form-div mt-8">
          <p class="label col-span-2">{{ !translate ? 'คำสำคัญของโครงงาน' : 'Project keyword' }} <sup> [3]</sup></p>
          <input v-model="project.keyword" type="text" placeholder="keyword1,keyword2,keyword3" class="col-span-3" required :disabled="is_advisor">
        </div>

        <!-- Keyword: format error -->
        <div v-if="!translate">
          <p v-if="val.keyword" class="project-text-error">กรอกข้อมูล "คำสำคัญของโครงงาน" ผิดรูปแบบ หรือกรอกข้อมูล "คำสำคัญของโครงงาน" น้อยกว่า 3 คำ</p>
          <small class="ml-4"><sup>[3]</sup> กรอกคำสำคัญที่เกี่ยวข้องกับโครงงานอย่างน้อย 3 คำ และกรอกในรูปแบบ คำสำคัญ1,คำสำคัญ2,คำสำคัญ3 เท่านั้น</small>
        </div>
        <div v-else>
          <p v-if="val.keyword" class="project-text-error">You fill out "Project keyword" in the wrong format or fill in the information less than 3 words.</p>
          <small class="ml-4"><sup>[3]</sup> Fill out at least 3 project-related keywords and fill in the form in keyword1,keyword2,keyword3 (This format) only</small>
        </div>

        <!-- Objective -->
        <div class="form-div mt-8">
          <p v-if="type === 'IS'" class="label col-span-2">{{ !translate ? 'จุดประสงค์' : 'Objectives' }}</p>
          <p v-else class="label col-span-2">{{ !translate ? 'วัตถุประสงค์' : 'Objectives' }}</p>
          <textarea v-model="project.objective" rows="5" class="col-span-3 pt-2" required :disabled="is_advisor"></textarea>
        </div>
 
        <!-- Background -->
        <div v-if="type !== 'IS'" class="form-div mt-8">
          <p class="label col-span-2">{{ !translate ? 'ที่มาและความสำคัญ' : 'Background and significance' }}</p>
          <textarea v-model="project.background" rows="5" class="col-span-3 pt-2" required :disabled="is_advisor"></textarea>
        </div>

        <!-- Process -->
        <div class="form-div mt-8">
          <p v-if="type === 'IS'" class="label col-span-2">{{ !translate ? 'แนวทางการศึกษา' : 'Methodology' }}</p>
          <p v-else class="label col-span-2">{{ !translate ? 'วิธีการดำเนินงาน' : 'Methodology' }}</p>
          <textarea v-model="project.process" rows="5" class="col-span-3 pt-2" required :disabled="is_advisor"></textarea>
        </div>

        <!-- Scope -->
        <div v-if="type !== 'IS'" class="form-div mt-8">
          <p class="label col-span-2">{{ !translate ? 'ขอบเขตของโครงงาน' : 'Scope' }}</p>
          <textarea v-model="project.scope" rows="5" class="col-span-3 pt-2" required :disabled="is_advisor"></textarea>
        </div>

        <!-- Benefit -->
        <div class="form-div mt-8">
          <p v-if="type === 'IS'" class="label col-span-2">{{ !translate ? 'ผลที่คาดว่าจะได้รับ' : 'Expected benefits' }}</p>
          <p v-else class="label col-span-2">{{ !translate ? 'ประโยชน์ที่คาดว่าจะได้รับ' : 'Expected benefits' }}</p>
          <textarea v-model="project.benefit" rows="5" class="col-span-3 pt-2" required :disabled="is_advisor"></textarea>
        </div>

        <!-- IS: Project type -->
        <div v-if="type === 'IS'" class="mt-8">
          <p class="label ml-4">ประเภทของโครงงาน (เลือกได้มากกว่า 1 ข้อ)</p>
          <div class="mt-4 ml-8">
            <input v-model="project.is_category.compare" type="checkbox" class="mr-8">
            <label class="my-auto">การศึกษาวิเคราะห์เชิงเปรียบเทียบตามหลักวิชาการ โดยจะต้องศึกษาและวิเคราะห์ในประเด็นที่ไม่เคยมีผู้ใดทำมาก่อน</label>
          </div>
          <div class="mt-4 ml-8">
            <input v-model="project.is_category.casestudy" type="checkbox" class="mr-8">
            <label class="my-auto">กรณีศึกษา โดยการประยุกต์ใช้ทฤษฎีตามหลักวิชาการเพื่อหาคำตอบหรือวิธีการแก้ไขปัญหาที่ชัดเจน</label>
          </div>
          <div class="mt-4 ml-8">
            <input v-model="project.is_category.analysis" type="checkbox" class="mr-8">
            <label class="my-auto">การวิเคราะห์และออกแบบโปรแกรมหรือระบบสารสนเทศ ควรประกอบด้วย Feasibility Study, Requirement Analysis, System Analysis, System Design</label>
          </div>
          <div class="mt-4 ml-8">
            <input v-model="project.is_category.develop" type="checkbox" class="mr-8">
            <label class="my-auto">การพัฒนาระบบหรือโปรแกรมถึงในระดับ Implementation โดยครอบคลุมการวิเคราะห์และออกแบบระบบ</label>
          </div>
        </div>

        <!-- IS Category: not have any True -->
        <p v-if="val.is_category" class="text-red-500 ml-8 mt-4">กรุณากรอกข้อมูลสำหรับ "ประเภทของโครงงาน" อย่างน้อย 1 ประเภท</p>

        <!-- IS: Choose -->
        <div v-if="type === 'IS'" class="form-div mt-8">
          <p class="label col-span-2">นักศึกษานำผลงานนี้ไปใช้ในหน่วยงานของตนหรือไม่</p>
          <div class="col-span-4 my-auto">
            <input v-model="project.is_category.agency" :value="true" type="radio" name="choose" class="mr-8">
            <label class="my-auto mr-8">ใช้</label>
            <input v-model="project.is_category.agency" :value="false" type="radio" name="choose" class="mr-8">
            <label class="my-auto">ไม่ใช้</label>
          </div>
        </div>

        <hr>
        <button type="submit" value="submit" class="submit-button" :disabled="isSubmitted">{{ !translate ? 'บันทึกข้อมูล' : 'Save' }}</button>
        
        <!-- Warning -->
        <div v-if="(validate_error || val_student_course || val_student_project) && !translate" class="text-red-500 mt-4">พบข้อผิดพลาดในข้อมูลที่กรอก กรุณาตรวจสอบข้อมูลอีกครั้ง (มีการแสดงข้อผิดพลาดภายใต้ข้อมูลที่กรอก)</div>
        <div v-else-if="(validate_error || val_student_course || val_student_project) && translate" class="text-red-500 mt-4">There was an error in the information you fill. Please check the information again. (Error is displayed under the information)</div>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import { projectValidate } from "../mixins/validate"
import { changeTypeName } from "../mixins/other"

export default {
  name: "ProjectEdit",
  components: {
    NavBar,
  },
  data () {
    return {
      project: {
        id: null,
        topic_th: '',
        topic_en: '',
        keyword: '',
        objective: '',
        process: '',
        scope: '',
        benefit: '',
        background: '',
        status: 'W',
        course: null,
        category: null,
        members: [],
        advisors: [],
        company: {
          name: '',
          advisor: '',
          email: ''
        },
        is_category: {
          compare: false,
          casestudy: false,
          analysis: false,
          develop: false,
          agency: false
        },
        course_name: '',
        course_type: '',
        program_detail: {id: null, name: '', code: ''},
        category_detail: {id: null, name: '', code: ''},
        member_details: [
          {name: '', code: ''},
          {name: '', code: ''}
        ],
        advisor_details: [
          {name: '', role: ''},
          {name: '', role: ''}
        ],
        course_details: {
            type: '',
        }
      },
      old_topic_th: '',
      old_topic_en: '',
      code_1: '',
      code_2: '',
      type: '',
      courses: [],
      categories: [],
      teachers: [],
      error: false,
      error_expired: false,
      loading: true,
      isSubmitted: false,
      role: null,
      advisor_status: true,
      is_advisor: false,
      val: {
        course: false,
        advisor_null: false,
        advisor_same: false,
        category: false,
        keyword: false,
        is_category: false,
        pass: false
      },
      val_student_project: false,
      val_student_course: false,
      translate: false
    }
  },
  async created() {
    const projectId = this.$route.params.pid
    const payload = this.$store.getters.payload
    this.role = this.$store.getters.payload.role
    this.courses = await this.$store.dispatch("getCourseNotExpiredByUserId", payload.user_id)
    this.courses.forEach((course) => { course.type_name = changeTypeName(course, 'projectEdit') + ' (' + course.program_code + '/' + course.year + ')' })
    this.categories = await this.$store.dispatch("getCategory")
    this.teachers = await this.$store.dispatch("getTeacher")

    // Edit Project
    if (projectId) {
      this.project = await this.$store.dispatch("getProjectById", projectId)
      this.type = this.project.course_details.type
      this.project.course_details.type_name = changeTypeName(this.project.course_details) + ' (' + this.project.course_details.program_code + '/' + this.project.course_details.year + ')'

      // Permission
      if (this.project.members.includes(this.$store.getters.payload.user_id) == false && this.role == 'STD' ||
          this.project.advisors.includes(this.$store.getters.payload.user_id) == false && this.role == 'TCH' ||
          this.project.advisors.includes(this.$store.getters.payload.user_id) && this.role == 'TCH' && this.type != 'CP') {
        this.$router.push("/")
      }

      // Check if this user is advisor
      if (this.project.advisors.includes(this.$store.getters.payload.user_id)) {
        this.is_advisor = true
      }

      this.old_topic_th = this.project.topic_th
      this.old_topic_en = this.project.topic_en

      // Setup advisor details
      if (this.project.advisors.length == 1) {
        this.project.advisors = [this.project.advisors[0], null]
        this.project.advisor_details[1] = { name: "-" } 
      } else {
        for(let ad in this.project.advisor_details) {
          if (this.project.advisor_details[ad].role == 'A') {
            this.project.advisors[0] = this.project.advisor_details[ad].user_id
          } else if (this.project.advisor_details[ad].role == 'C') {
            this.project.advisors[1] = this.project.advisor_details[ad].user_id
          }
        }
      }

      // Setup member details
      if (this.project.members.length == 0) {
        this.project.members = [null, null]
        this.project.member_details[0] = {id: null, name: '', code: ''}
        if (this.type == 'PJ')
          this.project.member_details[1] = {id: null, name: '', code: ''}
      } else if (this.project.members.length == 1) {
        this.project.members = [this.project.member_details[0].user_id, null]
        this.code_1 = this.project.member_details[0].code
        this.project.member_details[1] = {id: null, name: '', code: ''}
      } else {
        this.project.members = [this.project.member_details[0].user_id, this.project.member_details[1].user_id]
        this.code_1 = this.project.member_details[0].code
        this.code_2 = this.project.member_details[1].code
      }
      document.title = "แก้ไขรายละเอียดโครงงาน " + this.project.topic_th + " | Carbon" 
    } 
    
    // Create project
    else {
      // Permission
      if(this.role !== 'STD') {
        this.$router.push("/")
      }
      this.project.members = [payload.user_id, null]
      this.project.member_details[0] = {id: payload.user_id, name: payload.name, code: String(payload.code).padStart(8, '0')}
      this.project.advisors = [null, null]
      document.title = "แบบฟอร์มนำเสนอหัวข้อโครงงาน | Carbon"
    }

    // Error
    let cs = await this.$store.dispatch("getCourseByUserId", payload.user_id)
    // IF: this user not in any courses
    if (this.project.id == null && cs.length == 0) {
      this.error = true
      this.error_not_have_course = true
      this.loading = false
    } 
    
    // IF: 101 Form expired (Create project)
    else if (this.project.id == null && this.courses.length == 0) {
      this.error = true
      this.error_add_expired = true
      this.loading = false
    } 
    
    // IF: 101 Form expired (Edit project)
    else if (this.project.id != null && new Date(this.project.course_details.form_active_date_from) > new Date() && this.project.course_details.type != 'CP' ||
             this.project.id != null && new Date(this.project.course_details.form_active_date_to) < new Date() && this.project.course_details.type != 'CP') {
      this.error = true
      this.error_edit_expired = true
      this.loading = false
    } 
    
    // Not error
    else {
      this.loading = false
    }

    // IF: this project is coop
    if (this.project.course_details.type == 'CP') {
      this.loading = false
      this.error = false
      if (this.role === 'TCH' || new Date(this.project.course_details.form_active_date_from) < new Date() && new Date(this.project.course_details.form_active_date_to) < new Date()) {
        this.is_advisor = true
      } else {
        this.is_advisor = false
      }
    }

    // IF: this user is staff
    if(this.role === 'STF') {
      this.loading = false
      this.error = false
      this.is_advisor = false
    }
  
  },
  methods: {
    onChange() {
      this.type = this.courses.find(course => course.id === this.project.course).type
      if (this.project.id == null) {
        this.code = ''
        this.project.members[1] = null
        this.project.member_details[1] = {id: null, name: '', code: ''}
        this.project.advisors = [null, null]
      }
    },
    async submit() {
      if(!this.isSubmitted) {
        this.isSubmitted = true
        this.loading = true
        const data = this.project
        const projectId = this.project.id
        projectValidate(data, this.val, this.type)

        // Create project
        if (projectId === null && this.val.pass && !this.val_student_course && !this.val_student_project) {
          const result = await this.$store.dispatch("createProject", data)
          this.$router.push(`/project/${result.id}`)
        } 
        
        // Edit project
        else if (projectId != null && this.val.pass && !this.val_student_course && !this.val_student_project) {
          const result = await this.$store.dispatch("updateProject", data)
          this.$router.push(`/project/${result.id}`)
        } 
        
        // Error
        else {
          this.validate_error = true
          this.isSubmitted = false
          this.loading = false
        }
      }
    },
    async changeName(type) {
      if (this.code_1.length === 8 && type === "code_1" || this.code_2.length === 8 && type === "code_2") {
        let projectId = this.project.id === null ? '' : this.project.id
        let code = type === "code_1" ? this.code_1 : this.code_2
        const student = await this.$store.dispatch("getUserByCode", { code: code, course: this.project.course, project: projectId })

        if (student.has_other_project) {
          this.val_student_project = true
        } else if (student.not_in_course) {
          this.val_student_course = true
        }

        if (student != null && student.code != this.$store.getters.payload.code) {
          this.project.members[type === "code_1" ? 0 : 1] = student.id
          this.project.member_details[type === "code_1" ? 0 : 1] = {id: student.id, name: student.name, code: student.code, has_project: student.has_other_project, in_course: student.not_in_course}
        } 
      } else {
          this.val_student_project = false
          this.val_student_course = false
          this.project.members[type === "code_1" ? 0 : 1] = null
          this.project.member_details[type === "code_1" ? 0 : 1] = {id: null, name: '', code: '', has_project: false, in_course: false}
      }
    }
  },
  watch: {
    code_1() {
      this.changeName("code_1")
    },
    code_2() { 
      this.changeName("code_2")
    }
  },
}
</script>